export const getOverviewData = () => {
  return [
    {
      // sectionImage: 'Overview/credit-one-bank-club-logo.png',
      sectionTitle: "Raiders Locker Room",
      paragraph:
        "Excite your guests by hosting an event in the most iconic and exclusive event space in Allegiant Stadium.",
      area: "6,100",
      reception: "60",
      banquet: "60",
    },
  ];
};

export const getCarouselImages = () => {
  return ["RaidersLockerRoom/Photos/raiders-locker-room-01.jpg"];
};

export const getLayoutImages = () => {
  return ["RaidersLockerRoom/Layout/layout-raiders-locker-room.jpg"];
};

export const getEventPhotos = () => {
  return [
    "RaidersLockerRoom/events/raiders-locker-room-event-1.jpg",
    "RaidersLockerRoom/events/raiders-locker-room-event-2.jpg",
    "RaidersLockerRoom/events/raiders-locker-room-event-3.jpg",
    "RaidersLockerRoom/events/raiders-locker-room-event-4.jpg",
    "RaidersLockerRoom/events/raiders-locker-room-event-5.jpg",
  ];
};
